<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-8 col-md-6 col-lg-8">
            <h1 class="heading1 mt-0">Patient Identification <a href="#" data-bs-toggle="modal" data-bs-target="#patientdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h1>
          </div>
          <div class="col-12 col-sm-4 col-md-6 col-lg-4 text-sm-end" v-if="(this.filter == 'Pending') || (this.filter == '')">
            <button class="b-0 waves-effect waves-light btn btn-info" @click="sendemail"> Send Mail </button>
          </div> 
        </div>
      </div>
      <!--document model start-->
			<div id="patientdocument" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="myModalLabel" aria-hidden="true">
				<div class="modal-dialog modal-md">
					<div class="modal-content">
						<div class="modal-header">
							<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
						</div>
						<div class="modal-body overflow-auto" style="max-height: 350px;">
							<table class="table table-bordered">
								<thead>
									<tr>
										<th scope="col">No.</th>
										<th scope="col">Knowledge Base Articles</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>1</td>
										<td> <a :href="path + 'how-to-check-if-a-patient-has-submitted-identification-documents-in-pureehr'"
												target="_blank">How To Check If A Patient Has Submitted Identification
												Documents In PureEHR?</a></td>
									</tr>
								</tbody>
							</table>
							<Search :key="searchKey"></Search>
						</div>
						<div class="modal-footer">
							<button type="button" @click="" data-bs-dismiss="modal" aria-label="Close"
								class="waves-effect waves-light btn btn-danger">Close</button>
						</div>
					</div>
				</div>
			</div>
			<!--document model end-->
			<!--show email template start-->
			<div class="modal fade" id="showemailtemplate" tabindex="-1" role="dialog"
				aria-labelledby="exampleModalLongTitle" aria-hidden="true">
				<div class="modal-dialog modal-xl">
					<div class="modal-content">
						<div class="modal-header">
							<h3 class="modal-title fw-900 mt-0">Email Template</h3>
							<button type="button" data-bs-dismiss="modal" aria-label="Close"
								class="btn-close btn-xs"></button>
						</div>
						<div class="modal-body">
							<div>
								<h4 class="fw-600 mt-0 fs-20">To {{ this.patinet_name }},</h4>
								<p class="fs-14 l-h-20">I hope that you are doing well and continue to be blessed. This is
									Purity from Pure Wellness Medical. Due to the new year, we want to make sure that we
									have your most current healthcare insurance information up to date. Please attach a copy
									of your identification with the front and back of your insurance card using the
									following links.</p>
							</div>
							<div class="text-center">
								<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info mb-10">Update Your
									Document</a>
								<p class="mb-1 fs-14 l-h-20">Or Copy and paste the below URL into your browser.</p>
								<p class="fs-14 l-h-20">The link will expire in the next 48 hours.</p>
							</div>
							<div>
								<p class="fs-14 l-h-20">If you have any questions or concerns, please do not hesitate to us
									at your earliest convenience at <a href="javascript:void(0)"
										mailto="Info@PureWellnessMedical.com">Info@PureWellnessMedical.com</a> or
									855-679-7873.</p>
								<p class="fs-14 l-h-20">We truly appreciate all you do.</p>
								<p class="fs-14 l-h-10">Respectfully</p>
								<p class="fs-14 l-h-10">Pure Wellness Medical</p>
								<p class="fs-14 l-h-10">Live Well * Be Pure</p>
								<p class="fs-14 l-h-10">Get <b>OPTIMIZED</b></p>
							</div>
						</div>
						<div class="modal-footer">
							<button @click="closeshowemailtemplate" type="button" data-bs-dismiss="modal" aria-label="Close"
								class="waves-effect waves-light btn btn-danger">Close</button>
						</div>
					</div>
				</div>
			</div>
			<!--document model end-->
      <!--show email template start-->
      <div class="modal fade" id="showemailtemplate" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
         <div class="modal-dialog modal-xl">
            <div class="modal-content">
               <div class="modal-header">
                  <h3 class="modal-title fw-900 mt-0">Email Template</h3>
                  <button  type="button" data-bs-dismiss="modal" aria-label="Close" class="btn-close btn-xs"></button>
               </div>
               <div class="modal-body">
               </div>
               <div class="modal-footer">
                  <button @click="closeshowemailtemplate" type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
               </div>
            </div>
         </div>
      </div>
      <!--show email template end-->
      <section class="content">
        <div class="box">
          <div class="box-body">

            <div class="row justify-content-sm-left">
              <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="search"  v-model="patinet_search">
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <div class="form-group">
                  <select class="form-control" v-model ="filter" @change="search_patient">
                    <option value="">All</option>
                    <option value="Pending">Identification Pending</option>
                    <option value="Successfully">Identification Successfully</option>
                    <option value="withoutemail">Without Email</option>
                  </select>
                </div>
              </div>

              <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <div class="">
                    <button
                      class="b-0 waves-effect waves-light btn btn-info py-7 me-1"
                            @click="search_patient"
                    >
                        Search
                      </button>
                      <button
                        class="b-0 waves-effect waves-light btn btn-info-light py-7"
                        @click="clearFilter()" style="width: 81px;"
                      >
                        Clear
                      </button>
                    </div>
                </div>

            </div>

          </div>
        </div>

        <div class="box">
          <div class="box-body">

            <data-table-server dataType="patientIdentification" dataMethod="post" :key="key">
							<template v-slot="slotProps">			
								<td>
									<router-link :to="{ name: 'PatientDetails', params: { id: slotProps.item.unique_id },}" :title="'View Chart of ' + slotProps.item.patinet_name +'(' + slotProps.item.chart_id + ')'" class="fw-500">
										{{ slotProps.item.chart_id }}
									</router-link>
								</td>
								<td>{{ slotProps.item.patinet_name }}</td>
								<td>{{ slotProps.item.office_phone }}</td>
								<td>{{ slotProps.item.cell_phone }}</td>
                <td v-if="this.filter != 'withoutemail'">{{slotProps.item.email}}</td>
                <td v-if="this.filter != 'withoutemail'">
                  <div class="d-flex">
                    <Popper arrow content="Resend Verification Link" :hover="true" class="helptips cursor-pointer" v-if="this.filter != 'Successfully' && slotProps.item.email!=Null">
                      <a @click="resendVerificationLink(slotProps.item.id)" href="#" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1">
                        <i class="fa fa-paper-plane"></i>
                      </a>
                    </Popper>
                    <Popper arrow content="Show email template" :hover="true" class="helptips cursor-pointer">
                      <a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs" @click="showemailtemplate(slotProps.item.id)">
                        <i class="fa fa-eye"></i>
                      </a>
                    </Popper>
                    <Popper arrow content="Manually Verify" :hover="true" class="helptips cursor-pointer" v-if="slotProps.item.can_able_approve == true">
                      <a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs" @click="manuallyVerify(slotProps.item.id)">
                        <i class="fa fa-check"></i>
                      </a>
                    </Popper>
                  </div>
                </td>
                <td v-if="(this.filter == 'Pending') || ((this.filter == '') && (slotProps.item.is_pending == true))">
                  <div class="controls ">
                     <input v-bind:id="'checkbox_id'+slotProps.item.id" name="cardauthorization" type="checkbox" class="filled-in chk-col-info myCheckbox" :value="slotProps.item.id">
                     <label v-bind:for="'checkbox_id'+slotProps.item.id" class="m-0 text-start fs-16" style="padding-left: 18px;"></label>
                  </div>
                </td>
                <td v-else></td>
							</template>
						</data-table-server>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import Popper from "vue3-popper";
import Search from "@/components/Search.vue";
import DataTableServer from "@/components/base/DataTableServer.vue";


export default {
  mixins: [submitMixins],
  data() {
    return {
      key: 0,
      page_no:1,
      list:[],
      not_sent_email:[],
      errormsg :'',
      filter:'',
      file_type_error:'',
      csvform : {
        document_name : '',
      },
      patinet_name:'',
      patinet_id:'',
      list_lenght:'',
      start:0,
      end:25,
      step:25,
      steps:'',
      patinet_search :'',
      path: process.env.VUE_APP_KB_DOCUMENT,
      searchKey:0,
    };
  },
  components: {
      Popper,Search, DataTableServer
    },
  methods:{
    reset() {
      this.searchKey = this.searchKey + 1;
    },
    search_patient(){
      this.key++;
      this.$store.state.patientIdentification.patinet_search = this.patinet_search;
      this.$store.state.patientIdentification.status = this.filter;
    },
    pagejump(id){
      var last_id = parseInt(id * this.step);
      this.start = parseInt(last_id - this.step);
      this.end = last_id;
      $('.myCheckbox').prop('checked', false); 
      $('#checkAll').prop('checked', false); 
    },
    next(end){
      this.start = end;
      this.end = parseInt(end) + parseInt(this.step);
      $('.myCheckbox').prop('checked', false); 
      $('#checkAll').prop('checked', false); 
    },
    previous(end,start){
      this.start = parseInt(start)-parseInt(this.step);
      this.end = start;
      $('.myCheckbox').prop('checked', false); 
      $('#checkAll').prop('checked', false); 
    },
    sendemail(){
      if($("input:checkbox[name=cardauthorization]:checked").length == 0){
        this.moshaToast("Please select atleast one patient.", "danger");
        return false;
      }
      var patinet_id_array = Array();
        $("input:checkbox[name=cardauthorization]:checked").each(function() {
                patinet_id_array.push($(this).val());
        });
        this.patinet_id = patinet_id_array;
        
        this.$store.state.loader = true;
        axios.post("/reports/patient-identification-sendemail",{patinet_id:this.patinet_id})
        .then((res) => {
            if (res.data.status == 'success') {
              this.moshaToast("Email Sent successfully.", "success");
            } else if (res.data.status == 'error') {
              this.moshaToast(res.data.message, "danger")
            } else {
              this.moshaToast("Something went wrong.", "danger")
            }
            $('.myCheckbox').prop('checked', false)
            $('#checkAll').prop('checked', false)
            this.$store.state.loader = false
        });
    },
    checkall(){
      var checked = $('#checkAll').is(':checked');
      if(checked == true){
        $('.myCheckbox').prop('checked', true);
      }
      else{
        $('.myCheckbox').prop('checked', false); 
      }
    },
    showemailtemplate(id){
      this.$store.state.loader = true;
      axios.post("/reports/patient-identification/get-email-content",{patinet_id:id})
        .then((res) => {
          this.$store.state.loader = false;
          if (res.data.status == 'success') {
            var detail = res.data.data;
            console.log(detail.email_template)
            $("#showemailtemplate .modal-body").html(detail.email_template);
            $("#showemailtemplate").modal('show');
          } else if (res.data.status == 'error') {
            this.moshaToast(res.data.message, "danger")
          } else {
            this.moshaToast("Something went wrong.", "danger")
          }
      });
    },
    FileUpload(event) {
      if(this.$refs.file.files[0].type == "text/csv"){
        this.csvform.document_name = this.$refs.file.files[0];
        this.file_type_error = '';
      }
      else{
        this.file_type_error  ="Please upload a CSV file.";
      }
    },
    closecsvmodel(){
      this.file_type_error = '';
      if(this.not_sent_email == ''){
        this.csvform.csvform = '';
        this.not_sent_email = [];
        $("#csvmodalclose").click();
        $("#importe_csv").modal("hide");
      }
      else{
        location.reload();
      }

			this.$store.state.loader = true;
			axios.post("/reports/patient-identification-sendemail", { patinet_id: this.patinet_id })
				.then((res) => {
					this.moshaToast("Email Sent successfully.", "success"),
						$('.myCheckbox').prop('checked', false)
					$('#checkAll').prop('checked', false)
					this.$store.state.loader = false
				});
		},
		checkall() {
			var checked = $('#checkAll').is(':checked');
			if (checked == true) {
				$('.myCheckbox').prop('checked', true);
			}
			else {
				$('.myCheckbox').prop('checked', false);
			}
		},
		// showemailtemplate(name) {
		// 	this.patinet_name = name;
		// },
		FileUpload(event) {
			if (this.$refs.file.files[0].type == "text/csv") {
				this.csvform.document_name = this.$refs.file.files[0];
				this.file_type_error = '';
			}
			else {
				this.file_type_error = "Please upload a CSV file.";
			}
		},
		closecsvmodel() {
			this.file_type_error = '';
			if (this.not_sent_email == '') {
				this.csvform.csvform = '';
				this.not_sent_email = [];
				$("#csvmodalclose").click();
				$("#importe_csv").modal("hide");
			}
			else {
				location.reload();
			}

        axios.post("/payment-gateway-migration/send-email", bodyFormData)
        .then((res) => {
          if(res.data.status === "success"){
              this.$store.state.loader = false;
              this.moshaToast("Patients Email Send successfully.", "success");
              this.not_sent_email = res.data.data;
              //setTimeout(function(){ location.reload(); }, 3000);
          }else{
              this.$store.state.loader = false;
              this.moshaToast(res.data.message,'danger');
          } 
          if(this.not_sent_email == ''){
            $('#importe_csv').modal('hide');
            location.reload();
          }
        });  
    },
    filterchange(){
      this.$store.state.loader = true;
      axios.post("/reports/patient-identification",{'status':this.filter})
        .then((res) => {
          this.list = res.data.data;
          this.list_lenght = this.list.length;
          this.steps = parseInt(this.list_lenght / this.step);  
          this.$store.state.loader = false;
      });
    },
    resendVerificationLink(id){
      this.$store.state.loader = true;
      var patinet_id_array = Array();
      patinet_id_array.push(id);
      this.patinet_id = patinet_id_array;
      axios.post("/reports/patient-identification-sendemail",{patinet_id:this.patinet_id})
        .then((res) => {
          this.$store.state.loader = false;
          if (res.data.status == 'success') {
            this.moshaToast("Email Send successfully.", "success")
          } else if (res.data.status == 'error') {
            this.moshaToast(res.data.message, "danger")
          } else {
            this.moshaToast("Something went wrong.", "danger")
          }
      });
    },
    clearFilter() {
      this.patinet_search = '';
      this.filter = '';
      this.search_patient();
    },
    manuallyVerify(id){
      this.$store.state.loader = true;
      axios.post("/reports/approvePatientIdentity",{patinet_id:id})
        .then((res) => {
          this.$store.state.loader = false;
          if (res.data.status == 'success') {
            this.moshaToast("Patient Identification verified successfully.", "success")
            this.search_patient();
          } else if (res.data.status == 'error') {
            this.moshaToast(res.data.message, "danger")
          } else {
            this.moshaToast("Something went wrong.", "danger")
          }
      })
    },

  },  
  beforeCreate(){
    this.$store.state.loader = true;
    axios.post("/reports/patient-identification")
      .then((res) => {
        this.$store.state.loader = false;
        this.list = res.data.data;
        this.list_lenght = this.list.length;
        this.steps = parseInt(this.list_lenght / this.step);
    });
  },
  mounted() {
    $(document).on('change', '#checkAll', function(){
      var checked = $(this).is(':checked');
      if (checked == true) {
        $('.myCheckbox').prop('checked', true);
      }
      else {
        $('.myCheckbox').prop('checked', false);
      }
    });
  }
};
</script>