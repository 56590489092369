<template>
	<div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
		<div class="tab-content">
			<div class="tab-pane active" id="locked" role="tabpanel">
				<div class="authform-modal box">
					<div class="pageheading">
						<h2 class="heading2 font-bold" v-if="$route.params.appointments_id!=undefined && $route.params.appointments_id!=null && (form.billing_status_txt!='' || form.billing_status_txt!=null)">{{title}}
							<a href="#" data-bs-toggle="modal" data-bs-target="#appointmentdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a>
						</h2>
					</div>
					<form>
						<div v-if="$route.params.appointments_id!=undefined && $route.params.appointments_id!=null">
							<AppointmentInsuranceBadge :AppointmentNumber="$route.params.appointments_id" />
						</div>
						<div class="row form-group mb-10">
							<label class="form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Appointment Type</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-10 col-xl-10" :class="{ error: v$.form.type.$errors.length }">
								<input type="radio" v-model="form.type"  id="appointment_type4" name="appointment_type" value="Office" />
								<label class="me-10" for="appointment_type4">Office</label>
								<input type="radio" v-model="form.type"  id="appointment_type3" name="appointment_type" value="Tele Health" />
								<label class="me-10" for="appointment_type3">Tele Health</label>
								<input type="radio" v-model="form.type"  id="appointment_type1" name="appointment_type" value="WalkIn" />
								<label class="me-10" for="appointment_type1">Walk-In</label>
								<input type="radio" v-model="form.type"  id="appointment_type2" name="appointment_type" value="Phone" />
								<label class="me-10" for="appointment_type2">Phone</label>
								<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.type.$errors" :key="index"> {{ error.$message }} </div>
							</div>
						</div>

						<div class="row form-group mb-5" v-if="form.type=='Tele Health' && $route.name === 'EditAppointment' && form.vcallroom != null">
							
							<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">&nbsp;</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4">
								<a class="e-btn e-flat text-capitalize e-primary" target="_blank" 
								v-bind:href="'/#/telehealth/'+form.vcallroom+'/'+$filters.displayDateTimeLocal(form.schedule_time,'',false,'YYYY-MM-DD',true)+'/'+$filters.displayDateTimeLocal(form.schedule_time+' '+form.schedule_time,'',false,'HH:mm:ss',false,true)+'/'+$filters.displayDateTimeLocal(form.schedule_time+' '+form.schedule_end_time,'',false,'HH:mm:ss',false,true)" style="font-size: 0.9rem !important;">
									<i class="fa fa-video-camera"></i> Join call 
								</a>
							</div>
						</div>

						<div class="form-group row mb-5">
							<label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Office <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 mb-5" :class="{ error: v$.form.office_id.$errors.length }">
								<select class="form-control" v-model="form.office_id" @change="OfficeChange">
									<option value="" selected="selected" disabled="disabled">Select Office</option>
									<option v-for="(office,index) of offices" :value="office.id" :key="index">{{office.name}}</option>
								</select>
								<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.office_id.$errors" :key="index"> {{ error.$message }} </div>
								<div id="officeScheduleError" style="color:#ff3f3f"></div>                               
							</div>
							<label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Exam Room</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 mb-5">
								<select class="form-control" v-model="form.room_id" @change="changeroom">
									<option value="" disabled>Select Exam Room</option>
									<option v-for="(room,index) of rooms" :value="room.id" :key="index">{{room.name}}</option>
								</select>                                  
							</div>                            
						</div>

						<div class="form-group row mb-5">
							<label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Provider <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 mb-5 " :class="{ error: v$.form.provider_id.$errors.length, error: provider_not_allow }">
								<select class="form-control"  v-model="form.provider_id" ref="selectedProvider" @change="superviserCheck">
									<option value="" disabled="disabled">Select Provider</option>
									<option v-for="(provider,index) of providers" :value="provider.id" :data-is_supervisor="provider.is_supervisor" :key="index" :data-is_overlapping_allowed="provider.is_appointment_overlapping">{{provider.name}}</option>
								</select>  
								<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.provider_id.$errors" :key="index"> {{ error.$message }} </div>
								<div v-if="provider_not_allow" class="invalid-feedback" style="display:block;">You don't have access to add appointment for this provider. </div>
								<div id="providerScheduleError" style="color:#ff3f3f"></div>
								<small class="form-text text-muted"><b>Note:</b> Only patient's primary provider & supervising providers can be selected.</small>
							</div>
							<label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Supervising Manager <small v-if="$store.state.is_supervisor_required" class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4" :class="{ error: v$.form.supervisor_id.$errors.length }">
								<select class="form-control" v-model="form.supervisor_id">
									<option value="" disabled>Select Supervising Manager</option>
									<option v-for="(superprovider,index) of superproviders" :value="superprovider.id" :key="index">{{superprovider.name}}</option>
								</select>  
								<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.supervisor_id.$errors" :key="index"> {{ error.$message }} </div>
							</div>
						</div>
						<div class="form-group row mb-5" v-if="patienttextbox == 0">
							<label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Patient <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 d-flex mb-2">
								<div class="selectcontrols">
									<input type="text" disabled readonly :value="(patientDetail ? patientDetail.first_name+' '+patientDetail.last_name : '')" class="form-control" style="cursor: not-allowed;"/>
								</div>
								<div class="mr-5">
									<div class="portal_data">
										<Popper arrow content="Invite to PurePORTAL" :hover="true" class="helptips cursor-pointer">
											<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light btn-xs py-5 portal_active" @click="accesspPatientportal">
												<i class="fa fa-share-square-o" aria-hidden="true"></i>
											</a>
										</Popper>
									</div>
									<div class="portal_question d-none">
										<Popper arrow content="Send Questionnaire" :hover="true" class="helptips cursor-pointer">
											<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light btn-xs py-5 portal_active" @click="accessQuestionnarie">
													<i class="fa fa-brands fa-quora" aria-hidden="true"></i>
											</a>
										</Popper>
									</div>
								</div>
							</div>
							<label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Staff</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4">
								<select class="form-control" v-model="form.coordinator_staff_id" @change="changeCoordinator($event)">
									<option value="" data-need-provider-help-for-appt="1" >Select Staff</option>
									<option v-for="(officestaff,index) of officeStaffs" :value="officestaff.id" :data-need-provider-help-for-appt="officestaff.need_provider_help_for_appt" :key="index">{{officestaff.name}}</option>
								</select>
							</div>
						</div>
						
						<div class="form-group row mb-5" v-if="this.patient_package.length>0">
							<label class="form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end" v-if="allowOverLap == false">Select Package</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4" v-if="form.patientBillingId==null">
								<input type="text" disabled readonly :value="form.package_name+' [$'+form.price_per_treatment+']'" 
								class="form-control" style="cursor: not-allowed;" v-if="form.package_id!=null && form.package_id!='0'"/>
								<select class="form-control" @change="changepatientPackage($event)"  v-else>
									<option value=null>Select Package</option>
									<option v-for="(patientpackage,index) of patient_package" :value="patientpackage.id" :data-package_name="patientpackage.package_name" :data-package_per_price="patientpackage.price_per_treatment" :key="index">{{patientpackage.package_display_name}}</option>
								</select>
								<small class="form-text text-danger d-none" id="packageapp">Selected package will not be changed or removed once the appointment is saved successfully.</small>
							</div>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4" v-else>
							<input type="text" disabled readonly :value="form.package_name+' [$'+form.price_per_treatment+']'" 
								class="form-control" style="cursor: not-allowed;" v-if="form.package_id!=null && form.package_id!='0'"/>
								<input type="text" disabled readonly value="Select Package" 
								class="form-control" style="cursor: not-allowed;"  v-else/>
								<small class="form-text text-danger" id="packageapp1" v-if="form.package_id==null">Adding a package is not currently available as the billing has been charged for this appointment.</small>
							</div>
						</div>
						<div class="form-group row mb-5">
							<label class="form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end" v-if="allowOverLap == false"> Allow Overlapping </label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 mb-5" v-if="allowOverLap == false">
								<input type="checkbox" id="allow_overlapping" class="filled-in chk-col-info check_box" v-model="allowCheckBox" @change="ChangeAllowOverLapping">
								<label for="allow_overlapping"></label>
							</div>
							<label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end" v-show="form.coordinator_staff_id != ''">Is provider needed?</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4" v-show="form.coordinator_staff_id != ''">
								<input type="checkbox" id="checkbox_is_provider_needed" class="filled-in chk-col-info check_box" v-model="form.is_provider_occupied">
								<label for="checkbox_is_provider_needed" class="mb-0 mt-5 me-2">&nbsp;</label>
							</div>
						</div>

						<div class="form-group row mb-5" v-if="patienttextbox == 1">
							<label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Patient <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4">
								<div class="selectcontrols">
									<Autocomplete max="30" 
										@input="getlist" 
										ref="autocomplete" 
										:results="result" 
										@onSelect="resultsvalue" 
										class="form-control ms-0 autosearch-list" 
										placeholder="Search Patient"
										:use-html-for-results ="true"
										>                        
									  </Autocomplete>
								</div>
							</div>                      
						</div>
						
						<div class="form-group row mb-5">
							<label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Visit Profile</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 mb-5 d-flex">
								<select class="form-control me-2 h-36" style="height:36px;" v-model="form.appointment_profile_id" @change="onChangeAppointmentProfile">
									<option value="" disabled>Select Visit Profile</option>
									<option v-for="(appointmentprofile,index) of appointmentprofiles" :value="appointmentprofile.id" :key="index" :data-color_code="appointmentprofile.color_code" :data-default_duration="appointmentprofile.default_duration" :data-name="appointmentprofile.name" :data-reason_for_visit="appointmentprofile.reason_for_visit" :data-consent_form_ids="appointmentprofile.consent_form_ids">{{appointmentprofile.name}}</option>
								</select>
								<div class="w-p30">
									<ejs-colorpicker :value="default_color" id="inline-color-palette" mode="Palette" :modeSwitcher="true" :inline="false" :showButtons="false" :change="onChangeColor"></ejs-colorpicker>
								</div>
							</div>
							<label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Duration <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 availdate position-relative" :class="{ error: v$.form.duration.$errors.length }">
								<ejs-numerictextbox format="###" id="Duration" ref="numeric_instance" :value="duration_value" :step="5" :min="5" @change="durationChange"></ejs-numerictextbox>
								<small class="form-text text-muted position-absolute fs-14" style="right: 70px; top: 2px;">Minutes</small>
								<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.duration.$errors" :key="index"> {{ error.$message }} </div>
							</div>
						</div>
						<div class="form-group row mb-5" v-if="this.signedConsentFormmsg!=''">
							<label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Consent Forms</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4">
								<div class="d-flex justify-content-between">
									<div class="availdate mb-2 me-2 w-p100">
										<ejs-multiselect id='multiselect' @change="consentformsChange" 
										:dataSource='consentforms' :value="selected_forms" :fields='default_fields' 
										:mode='boxMode' placeholder="Select Consent Forms"></ejs-multiselect>
									</div>
									<Popper arrow :content="this.signedConsentFormmsg"  :hover="true" class="helptips cursor-pointer">
										<div class="text-success"><i class="fa fa-info-circle" aria-hidden="true"></i></div>                                     
									</Popper>
								</div>
							</div>

							<label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Reason <small class="text-danger asterisksign">*</small> </label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 mb-5" :class="{ error: v$.form.reason_for_visit.$errors.length }">
								<textarea name="textarea" rows="2" v-model="form.reason_for_visit" id="reason_for_visit" class="form-control" placeholder="Reason"></textarea>
								<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.reason_for_visit.$errors" :key="index"> {{ error.$message }} </div>
							</div>
						</div>
						<div class="form-group row mb-5" v-else>
							<label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Consent Forms</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 availdate mb-2">
								<ejs-multiselect id='multiselect' @change="consentformsChange" :dataSource='consentforms' :value="selected_forms" :fields='default_fields' :mode='boxMode' placeholder="Select Consent Forms"></ejs-multiselect>	
							</div>

							<label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Reason <small class="text-danger asterisksign">*</small> </label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 mb-5" :class="{ error: v$.form.reason_for_visit.$errors.length }">
								<textarea name="textarea" rows="2" v-model="form.reason_for_visit" id="reason_for_visit" class="form-control" placeholder="Reason"></textarea>
								<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.reason_for_visit.$errors" :key="index"> {{ error.$message }} </div>
							</div>
						</div>
						<div class="form-group row mb-5">
							<label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Schedule Start Time <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 availdate " :class="{ error: v$.form.schedule_time.$errors.length }">
								<ejs-datetimepicker id="schedule_time" placeholder="mm-dd-yyyy, --:-- --" :value="schedule_time" :showClearButton='false' :format="dateFormat" @change="scheduleDate" :step="starttime_step" :openOnFocus='true' @open="timeopen"></ejs-datetimepicker>
								<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.schedule_time.$errors" :key="index"> {{ error.$message }} </div>
								<div class="invalid-feedback" style="display:block;" v-if="this.errormessage != null && this.schedule_type== ''"> {{ this.errormessage }}
								<a href="javascript:void(0)" class="fs-14 fw-600 text-decoration-underline" data-bs-toggle="modal" data-bs-target="#appointmentscheduling" @click="suggestion_schedule" v-if="this.userschedule == true" >Click Here to view free slots</a> </div>								
							</div>
							<label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">End Time <small class="text-danger asterisksign">*</small> </label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 availdate" :class="{ error: v$.form.schedule_end_time.$errors.length }">
								<ejs-datetimepicker id="schedule_end_time" placeholder="mm-dd-yyyy, --:-- --" :value="schedule_end_time" :showClearButton='false' :format="dateFormat" @change="scheduleEndDate" step="15" @open="timeopen"></ejs-datetimepicker>
								<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.schedule_end_time.$errors" :key="index"> {{ error.$message }} </div>
							</div>
						</div>
						<div class="form-group row mb-5">
							<label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Status <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 mb-5" :class="{ error: v$.form.status.$errors.length }">
								<select v-model="form.status" id="status" class="form-select mb-5" @change="statusChange($event)">
									<option v-for="(status,index) of appointmentstatus" :value="status.id" :key="index" :selected="status.name == 'Not Confirmed'">{{status.name}}</option>
								</select>
								<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.status.$errors" :key="index"> {{ error.$message }} </div>
							</div>
							<label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Notes</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 mb-5">
								<textarea name="textarea" rows="2" v-model="form.extra_notes" id="extra_notes" class="form-control" placeholder="Notes"></textarea>
							</div>
						</div>
						<div class="form-group row mb-5" v-if="this.form.status == 9">
							<label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Rescheduled Time<small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 availdate">
								<ejs-datetimepicker id="rescheduled_time" placeholder="mm-dd-yyyy, --:-- --" :value="rescheduled_time" :showClearButton='false' :format="dateFormat" @change="rescheduleeDate" step="15"></ejs-datetimepicker>
								<div class="invalid-feedback" style="display:block;" v-if="this.errormessage != null && this.schedule_type != ''"> {{ this.errormessage }} <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#appointmentscheduling" @click="suggestion_schedule" v-if="this.userschedule == true" >Click Here to view free slots</a> </div>
							</div>
						</div>
						<div class="form-group row mb-5">
							<label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">CRM Status</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4">
								<select class="form-control mb-2" v-model="form.crm_prospect_patient_status">
									<option value="" >Select CRM Status</option>
									<option v-for="(crmStatus,index) of crmStatusLists" :value="crmStatus.id" :key="index">{{crmStatus.name}}</option>
								</select>
							</div>
							<slot v-if="appEnv=='development'">
								<label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Diagnosis Code </label>
								<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 mb-5">
									<input type="text" class="form-control" placeholder="Enter Diagnosis code" v-model="form.principaldiagnosiscode">
								</div>
							</slot>
						</div>
						<slot v-if="appEnv=='development'">
							<div class="form-group row mb-5">							
								<label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Discharge Disposition </label>
								<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 mb-5">
									<input type="text" class="form-control" placeholder="Enter discharge disposition code" v-model="form.dischargecode">
								</div>
							</div>
						</slot>
						<hr>
						<!-- Recurring Appointments Start -->
						<div class="form-group mb-10" v-if="this.$route.name === 'PatientAppointment'">
							<input type="checkbox" id="recurring_appointments" value="0" class="filled-in chk-col-info check_box" v-model="form.is_recurring" @change="recurring" :disabled="recurringform == true">
							<label for="recurring_appointments" class="mb-0">Recurring Appointments <small>{{recurring_error}}</small></label>
						</div>
						<fieldset class="table_filters" v-show="form.is_recurring == true">
							<div class="box bg-lightest">
								<div class="box-body">
									<div class="row justify-content-sm-center">
										<div class="form-group mb-5 row">

											<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-5">
												<label class="col-form-label mb-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">Repeat</label>
												<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
													<select class="form-control" v-model="frequency" @change="getrecurring_end_date">
														<option value="Week(s)" selected="selected">Weekly</option>
														<option value="Month(s)">Monthly</option>
													</select>
												</div>
											</div>

											<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-5">
												<label class="col-form-label mb-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">Repeat every</label>
												<div class="form-group row">
													<div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
														<input type="number" class="form-control e-repeat-interval e-control e-numerictextbox e-lib" title="Repeat every" role="spinbutton" autocomplete="off" aria-live="assertive" v-model="recurs_every" min="1" :max="repeat_every_max" @change="getrecurring_end_date"  @blur="getrecurring_end_date">
													</div>
													<div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
														{{this.frequency}}
													</div>
												</div>
											</div>

											<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-5" v-show="frequency=='Week(s)'">
												<label class="col-form-label mb-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">Repeat On</label>
												<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
													
													<input type="checkbox" id="checkbox_2" value="SU" data-id="Sunday" class="filled-in chk-col-info weekday_checkbox Su" name="Weekday">
													<button type="button" class="btn btn-secondary btn-sm btn_weekdays btn_Su" @click="weekday_btn_click('Su')">S</button>
													<label for="checkbox_2" class="mb-0 mt-5 me-2">&nbsp;</label>

													<input type="checkbox" id="checkbox_3" value="MO" data-id="Monday" class="filled-in chk-col-info weekday_checkbox Mo" name="Weekday">
													<button type="button" class="btn btn-secondary btn-sm btn_weekdays btn_Mo" @click="weekday_btn_click('Mo')">M</button>
													<label for="checkbox_3" class="mb-0 mt-5 me-2">&nbsp;</label>
													
													<input type="checkbox" id="checkbox_4" value="TU" data-id="Tuesday" class="filled-in chk-col-info weekday_checkbox Tu" name="Weekday">
													<button type="button" class="btn btn-secondary btn-sm btn_weekdays btn_Tu" @click="weekday_btn_click('Tu')">T</button>
													<label for="checkbox_4" class="mb-0 mt-5 me-2">&nbsp;</label>

													<input type="checkbox" id="checkbox_5" value="WE" data-id="Wednesday" class="filled-in chk-col-info weekday_checkbox We" name="Weekday">
													<button type="button" class="btn btn-secondary btn-sm btn_weekdays btn_We" @click="weekday_btn_click('We')">W</button>
													<label for="checkbox_5" class="mb-0 mt-5 me-2">&nbsp;</label>
													
													<input type="checkbox" id="checkbox_6" value="TH" data-id="Thursday" class="filled-in chk-col-info weekday_checkbox Th" name="Weekday">
													<button type="button" class="btn btn-secondary btn-sm btn_weekdays btn_Th" @click="weekday_btn_click('Th')">T</button>
													<label for="checkbox_6" class="mb-0 mt-5 me-2">&nbsp;</label>
													
													<input type="checkbox" id="checkbox_7" value="FR" data-id="Friday" class="filled-in chk-col-info weekday_checkbox Fr" name="Weekday">
													<button type="button" class="btn btn-secondary btn-sm btn_weekdays btn_Fr" @click="weekday_btn_click('Fr')">F</button>
													<label for="checkbox_7" class="mb-0 mt-5 me-2">&nbsp;</label>
													
													<input type="checkbox" id="checkbox_8" value="SA" data-id="Saturday" class="filled-in chk-col-info weekday_checkbox Sa" name="Weekday">
													<button type="button" class="btn btn-secondary btn-sm btn_weekdays btn_Sa" @click="weekday_btn_click('Sa')">S</button>
													<label for="checkbox_8" class="mb-0 mt-5 me-2">&nbsp;</label>
												</div>
											</div>

											<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-5" v-show="frequency=='Month(s)'">
												<label class="col-form-label mb-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">Repeat On</label>
												<div class="form-group row">
													<div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2 mb-5">
														<input type="radio" v-bind:id="'radio_1'" value="date" :checked="repeat_on=='date'" class="filled-in chk-col-info" name="repeat_on" v-model="repeat_on" @change="getrecurring_end_date">
														<label for="radio_1" class="mb-0 mt-5 me-2">Date</label>
													</div>
													<div class="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-10 mb-5">
														<input type="number" class="form-control e-repeat-interval e-control e-numerictextbox e-lib" title="Day" role="spinbutton" autocomplete="off" aria-live="assertive" min="1" max="31" v-model="monthDay" @change="getrecurring_end_date" @blur="getrecurring_end_date">
													</div>
												</div>
												<div class="form-group row">
													<div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2 mb-5">
														<input type="radio" v-bind:id="'radio_2'" value="day" :checked="repeat_on=='day'" class="filled-in chk-col-info" name="repeat_on" v-model="repeat_on" @change="getrecurring_end_date">
														<label for="radio_2" class="mb-0 mt-5 me-2">Day</label>
													</div>
													<div class="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-10 mb-5">
														<div class="form-group row">
															<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-5">
																<select class="form-control" v-model="bystep" @change="getrecurring_end_date">
																	<option value="1">First</option>
																	<option value="2">Second</option>
																	<option value="3">Third</option>
																	<option value="4">Fourth</option>
																	<option value="-1">Last</option>
																</select>
															</div>
															<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-5">
																<select class="form-control" v-model="byday" @change="getrecurring_end_date">
																	<option value="MO">Monday</option>
																	<option value="TU">Tuesday</option>
																	<option value="WE">Wednesday</option>
																	<option value="TH">Thursday</option>
																	<option value="FR">Friday</option>
																	<option value="SA">Saturday</option>
																	<option value="SU">Sunday</option>
																</select>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-5">
												<label class="col-form-label mb-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">End</label>
												<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
													<div class="form-group row">
														<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-5">
															<select class="form-control" v-model="endType" @change="getrecurring_end_date">
																<option value="never" selected="selected">Never</option>
																<option value="until">Until</option>
																<option value="count">Count</option>
															</select>
														</div>
														<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-5 availdate" v-show="endType=='until'">
															<ejs-datepicker
																class="form-control" 
																:value="recurring_end_date" 
																id="recurring_end_date" 
																v-model="recurring_end_date" 
																:format="recurring_dateFormat" 
																placeholder="" 
																:strictMode='strict' 
																:min ="minVal"
																:showClearButton='false' 
																:openOnFocus='true'
																@change="getrecurring_end_date">
															</ejs-datepicker>
														</div>
														<ejs-recurrenceeditor style="display: none;" id='editor' ref='EditorObj' ></ejs-recurrenceeditor>
														<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-5" v-show="endType=='count'">
															<input type="number" id="count" min="1" max="52" value="1" class="form-control" @change="getrecurring_end_date">
														</div>
													</div>
												</div>
											</div>

										</div>
										
									</div>
								</div>
							</div>
						</fieldset>						
						<!-- Recurring Appointments End -->

						<!-- Active Reminders Start -->
						<div class="form-group mb-10">
							<input type="checkbox" id="checkbox_11" value="single" class="filled-in chk-col-info manual_box" v-model="form.is_reminder" :checked="form.is_reminder" @change="reminder_list" :disabled="form.schedule_time == null">
							<label for="checkbox_11" class="mb-0">View Active Reminders</label>
						</div>
						<fieldset class="manual_entry" v-show="form.is_reminder == true">
							<div class="form-group row align-items-center">
								<div class="col-12 col-sm-6 col-md-4 col-lg-3 text-sm-end">
									<label class="form-label">Choose a Reminder Profile</label>
								</div>
								<div class="col-12 col-sm-12 col-md-8 col-lg-3">
									<select name="select" id="salutationselect" class="form-select" @change="reminder_list">
		                           		<option value="" selected="selected" disabled="disabled">Select Reminder Profile</option>
		                           		<option v-for="(item,index) in reminderlist" :value="item.id" :selected="getReminderProfileSelected(item)" :key="index">{{item.name}}</option>
	                        		</select>
								</div>
							</div>

							<div v-for="(item,index) in form.reminderdata" :key="index">
								<div class="form-group row align-items-center" v-bind:id="'form'+index">
									<div class="col-12 col-md-8">
										<div class="row">
											<div class="col-12 col-md-2" v-bind:id="'reminder_type'+index">
												<select name="select" class="form-select form-control mb-10" v-model="form.reminderdata[index].reminder_type" @change="reminderTypeChange(index)" disabled>
													<option value="email" :selected="form.reminderdata[index].reminder_type == 'email'">E-Mail</option>
													<option value="phone" :selected="form.reminderdata[index].reminder_type == 'phone'">Phone</option>
													<option value="sms" :selected="form.reminderdata[index].reminder_type == 'sms'">SMS</option>					
												</select>
											</div>
											<div class="col-12 col-md-2" v-bind:id="'reminder_value_'+index">
												<input type="text" v-bind:id="'reminder_value'+index" class="form-control" placeholder="1" v-model="form.reminderdata[index].reminder_value">
											</div>
											<div class="col-12 col-md-2">
												<select name="select" class="form-select form-control mb-10" v-model="form.reminderdata[index].reminder_unit_type">
													<option value="days" :selected="form.reminderdata[index].reminder_unit_type == 'days'">Day(s)</option>
													<option value="hours" :selected="form.reminderdata[index].reminder_unit_type == 'hours'">Hour(s)</option>
												</select>
											</div>
											<div class="col-12 col-md-2" v-bind:id="'reminder_unit_before_or_after_'+index">
												<select name="select" class="form-select form-control" v-bind:id="'reminder_unit_before_or_after'+index">
													<option value="" selected="selected" disabled="disabled">Select Value</option>
													<option value="before" :selected="form.reminderdata[index].reminder_unit_before_or_after == 'before'">Before</option>
													<option value="after"  :selected="form.reminderdata[index].reminder_unit_before_or_after == 'after'">After</option>
												</select>
											</div>
											<div class="col-12 col-md-4" v-bind:id="'reminder_content_'+index">
											
												<select name="select" class="form-select form-control" v-bind:id="'reminder_content'+index" @change="reminder_content(index)">
													<option selected="selected" disabled="disabled">Select email template</option>
													<option v-for="(itemdata,index1) in emaillist" :selected="form.reminderdata[index].reminder_content == itemdata.id" :value="itemdata.id" :key="index1">{{itemdata.template_name}}</option>
												</select>
											</div>
										</div>
									</div>
									<div class="col-12 col-md-4 text-end">
										<button @click="delete_reminder(index)" type="button" class="waves-effect waves-light btn btn-danger btn-sm me-5"><i class="fa fa-trash" aria-hidden="true"></i></button>
										<button @click="reminderview(index)" type="button" class="waves-effect waves-light btn btn-info btn-sm me-5" data-bs-toggle="modal" data-bs-target="#mypreviewModal"><i class="fa fa-eye"></i> Preview</button>
									</div>
								</div>
							</div>
							<div class="form-group row align-items-center">
								<div class="col-12 col-md-12 alert alert-danger" v-show="this.$store.state.patientDetail.email == ''">
									<label class="form-label">This patient has no Email so Email reminders will not trigger.</label>
								</div>
								<div class="col-12 col-md-12 alert alert-danger" v-show="this.$store.state.patientDetail.cell_phone == ''">
									<label class="form-label">This patient has no Phone so Phone reminders will not trigger.</label>
								</div>
								<div class="col-12 col-md-12">
									<label class="form-label">{{warning}}</label>
								</div>
							</div>

						</fieldset>
						<!-- Active Reminders END -->
					</form>
					<div class="text-center">
						<button @click="submitForm" v-if="form.billing_status_txt!='Fully Billed - Payment Locked'" class="waves-effect waves-light btn btn-info me-1">{{button}}</button>
						<button @click="close" class="waves-effect waves-light btn btn-danger me-1">Close</button>
					</div>
					<!--document model start-->
					<div id="appointmentdocument" class="modal fade" tabindex="-1" role="dialog"
						aria-labelledby="myModalLabel" aria-hidden="true">
						<div class="modal-dialog modal-md">
							<div class="modal-content">
							<div class="modal-header">
							<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
							</div>
							<div class="modal-body overflow-auto" style="max-height: 350px;">
							<table class="table table-bordered">
								<thead>
									<tr>
									<th scope="col">No.</th>
									<th scope="col">Knowledge Base Articles</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>1</td>
										<td> <a :href="path+'how-to-schedule-new-appointments'" target="_blank">How To Schedule New Appointments?</a></td>
									</tr>
									<tr>
										<td>2</td>
										<td> <a :href="path+'how-to-overlap-for-appointment'" target="_blank">How To Overlap For Appointment?</a></td>
									</tr>
									<tr>
										<td>3</td>
										<td> <a :href="path+'how-to-make-recurring-appointment'" target="_blank">How To Make Recurring Appointment?</a></td>
									</tr>
								</tbody>
							</table>
							<Search :key="searchKey"></Search>
							</div>
							<div class="modal-footer">
							<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
							</div>
							</div>
						</div>
					</div>
   					<!--document model end-->
				</div>
			</div>
		</div>
	</div>

   <!--model start-->
   <div id="appointmentscheduling" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="appointmentscheduling" aria-hidden="true">
	  <div class="modal-dialog modal-sm">
		 <div class="modal-content">
			<div class="modal-header">
			   <h3 class="modal-title fw-900 mt-0">CHOOSE A FREE SLOT</h3>
			</div>
			<div class="modal-body">
			   <img src="images/loader.gif" v-if="choose_slot_loader" class="mt-50 img-fluid" style="width:50px;margin-left:125px">
			   <div class="form-group mb-0" v-for="(item,index) in suggestion_list.slice(0,5)" :key="index" >
					<input v-model="suggestion_time" type="radio" v-bind:id="'suggestion'+index" name="suggestion" class="filled-in chk-col-info" :value="item.schedule_time">
					<label v-bind:for="'suggestion'+index">{{item.value}}</label>
			   </div>

				<div class="free_slot mb-10">
					<div class="form-group mb-0" v-for="(item,index) in suggestion_list.slice(6)" :key="index" >
						<input v-model="suggestion_time" type="radio" v-bind:id="'qsuggestion'+index" name="suggestion" class="filled-in chk-col-info" :value="item.schedule_time">
						<label v-bind:for="'qsuggestion'+index">{{item.value}}</label>
				   </div>
				</div>
				<button type="button" class="waves-effect waves-light btn btn-light float-end check_box">more</button>
			</div>
			<div class="modal-footer">
			   <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-info" @click="Schedule">Schedule</button>
			   <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
			</div>
		 </div>
	  </div>
   </div>
   <!--model end-->
   <!-- Recurring model start-->
   <div id="recurringscheduling" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="recurringscheduling" aria-hidden="true">
	  <div class="modal-dialog modal-md">
		 <div class="modal-content">
			<div class="modal-header d-block">
			   <h3 class="modal-title fw-900 mt-0">Select a new time slot</h3>
			   <p class="fs-14 mb-0">There is/are already appointment(s) booked for date/time/room. Please select time from free slots listed below:</p>
			</div>
			<div class="modal-body">			  
				<div class="row" v-for="(items,index) in recurrenceerrordate" :key="index">
					<div class="col-6 col-sm-6 col-md-6 col-lg-6">
						{{items.date}}	
					</div>
					<div class="col-6 col-sm-6 col-md-6 col-lg-6">
						<div class="form-group">
							<select class="form-control" v-bind:id="'free_sloat'+index">
								<option v-for="(item,index1) in items.slot" :value="item.schedule_time" :key="index1">{{item.value}}</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
			   <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-info" @click="sloat_book(recurrenceerrordate.length)">Schedule</button>
			   <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
			</div>
		 </div>
	  </div>
   </div>

<!--Reminder email preview Modal start -->
<div id="mypreviewModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<h2 class="modal-title" id="myModalLabel">Email Message Preview</h2>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="d-flex mb-3">
					<strong>Subject: </strong>{{ subjectline}}
				</div>
				<div v-html="body_content"></div>
			</div>
			<div class="modal-footer">
				<button type="button" class="addpatientbtn btn-danger b-0" data-bs-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>
<!--Reminder email preview Modal End -->
</template>
<script>
import { mapGetters } from "vuex";
import axios from "@/scripts/axios.js";
import SelectBox from "@/components/base/formFields/selectBox.vue";
import useVuelidate from "@vuelidate/core";
import moment from 'moment';
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import Autocomplete from "vue3-autocomplete";
import AppointmentInsuranceBadge from "@/components/Appointments/AppointmentInsuranceBadge.vue";
import Search from "@/components/Search.vue";
import Popper from "vue3-popper";

export default {
	mixins: [submitMixins,permissionMixins],
	setup: () => {
		return { v$: useVuelidate() };
	},
	components: { SelectBox,Autocomplete,AppointmentInsuranceBadge, Search, Popper },
	data (){
		return {      
			appEnv: process.env.VUE_APP_NODE_ENV,
			starttime_step:15,      
			repeat_on:'date',
			boxMode: 'Box',
			patienttextbox :0,
			result: [],
			patientDetail : '',
			appointmentstatus: [],
			offices:[],
			crmStatusLists:[],
			rooms:[],
			providers:[],
			accessproviders:[],
			superproviders:[],
			officeStaffs:[],
			appointmentprofiles:[],
			default_color:'#0265a0',
			duration_value:5,
			consentforms: [],
			default_fields : { text: 'name', value: 'id' },
			dateFormat : 'MM-dd-yyyy hh:mm a',
			schedule_time : '',
			schedule_end_time: '',
			selected_forms: [],
			provider_not_allow: false,
			errormessage:null,
			suggestion_list:'',
			suggestion_time:'',
			userschedule:false,
			schedule_type:'',
			recurs_every:1,
			recurring_start_date : '',
			strict: true,
			recurring_end_date :'',
			never_ends:'',
			recurring_dateFormat: 'MM-dd-yyyy',
			minVal: '',
			recurrencedate:[],
			frequency:'Week(s)',
			numbersOnly:1,
			byday:'MO',
			bystep:'1',
			endType: 'never',
			recurringerrormessage:null,
			recurrenceerrordate:[],
			reminderlist:[],
			emaillist:[],
			subjectline:'',
			body_content:'',
			warning:'',
			recurring_error:'(Select Start Time to enable recurring appointments)',
			recurringform :true,
			recurring_start_time:'',
			choose_slot_loader:false,
			pre_appointment:'',
			selected_package:'',
			selected_package_error:'',
			package_action:false,
			patient_package : [],
			allowOverLap: false,
			allowCheckBox: false,
			path: process.env.VUE_APP_KB_DOCUMENT,
			searchKey:0,
			monthDay:1,
			repeat_every_max: 52,
			package_id:0,
			package_name:'',
			package_per_price:'',
			signedConsentForm:[],
			signedConsentFormmsg:'',
			reminderProfileChanged: false,
		}
		
	},
	mounted() {
		
		this.form.pre_appointment = '';
		if(this.$route.name == "PatientAppointment" && localStorage.getItem("office_access")){
			this.$store.state.scheduleappointment.form.office_id = localStorage.getItem("office_access");
			this.OfficeChange();
		}		     
		if(localStorage.getItem("exam_duration")){
			this.duration_value = localStorage.getItem("exam_duration");
			this.$store.state.scheduleappointment.form.duration = localStorage.getItem("exam_duration");
		}		       
		if(localStorage.getItem("calendar_increament")){
			this.starttime_step = localStorage.getItem("calendar_increament");
		}		       
		
		if(this.$route.params.id == 'new'){
			this.patienttextbox = 1;
			this.schedule_time = moment(this.$store.state.scheduleappointment.date).format('MM-DD-YYYY');
		}
		if (this.$route.name === "PatientAppointment" && !this.form.pre_appointment) {
			setTimeout(() => {
				this.form.status = 1;
				this.form.type = "Office";
				this.form.action = "added";
				this.form.manual_appointment = true;            
			}, 1000);
		}        
		var current_date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
		axios.get("/patient/" + this.$route.params.id,{
			params: { audit_log: false, current_date: current_date },
		  })
		.then((response) => {
			this.patientDetail = response.data.data;   
			this.form.patient_id = this.patientDetail.id;
			this.getPatinetPackage(this.form.patient_id);
			
			this.$store.state.scheduleappointment.patient_id = this.patientDetail.id;
			if (this.$route.query.pre_appt_id) {
				this.getPreAppointmentInfoById(this.$route.query.pre_appt_id);
			}
			var patient_id = this.patientDetail.id;
			axios.post("patient/getbyid",{patient_id:patient_id})
			.then((response) =>{
				var data = response.data.data;
				$(".portal_active").attr("data-id",patient_id);
				$(".portal_active").attr("data-email",data.email);
				$(".portal_active").attr("data-dob",data.date_of_birth);
				$(".portal_active").attr("data-ppid",data.pp_id);
				$(".portal_active").attr("data-cellphone",data.cell_phone);
				$(".portal_active").attr("data-uniqueid",data.unique_id);
				$(".portal_active").attr("data-pp_status",data.pp_status);
				this.$store.state.patientDetail.patient_id = patient_id;
				this.$store.state.patientDetail.patient_unique_id = data.unique_id;
				this.$store.state.patientDetail.email = data.email;
				this.$store.state.patientDetail.cell_phone = data.cell_phone;
				if (this.$route.name === "EditAppointment") {
					$(".portal_question").attr('data-appointment_id',this.$route.params.appointments_id);
					$(".portal_question").attr('data-patient_id',patient_id);
					$(".portal_question").attr('data-clinic_id',data.clinic_id);
				}
				if (data.pp_id > 0 && data.legacy_source == "Pure Form" && this.$route.name === "EditAppointment") {
					$(".portal_question").removeClass("d-none");
					$(".portal_data").addClass("d-none");
				} 
				else if (data.pp_id > 0 ) {
					$(".portal_data").addClass("d-none");
					$(".portal_question").addClass("d-none");
				}
				else {
					$(".portal_data").removeClass("d-none");
					$(".portal_question").addClass("d-none");
				}
			});
		});
	},
	async beforeCreate() {
		axios.get('/reminder/list')
		.then((response) => {
	    	this.reminderlist = response.data.data 
	    });
	    axios.post('/email-template/list')
	    .then((response) => {
	    	this.emaillist = response.data.data;
	    });
		axios.get("/json_dropdown/appointment-profile")
		.then((response) => {
			this.appointmentprofiles = response.data.data;            
		});        
		axios.post("/get-user-provider")
		.then((response) => {
			this.accessproviders = response.data.data; 
		});
		axios.get("/json_dropdown/appointment-status")
		.then((response) => {
			this.appointmentstatus = response.data.data; 
		});
		axios.get("/json_dropdown/consent-form")
		.then((response) => {
			this.consentforms = response.data.data;            
		});
		var flag = '';
		if (this.$route.name === "EditAppointment") {
			flag = 'editapi';
		}
		axios.get("/json_dropdown/get-user-office-list")
		.then((response) => (
			this.offices = response.data.data,
			this.OfficeChange(flag)
		));
		axios.get(`/json_dropdown/appointment-crm-prospect-status`)
			.then((res) => {
			this.crmStatusLists = res.data.data;
		});
		if (this.$route.name === "PatientAppointment") {
			this.$store.state.scheduleappointment.form = {};
			this.$store.state.scheduleappointment.form.office_id="";
			this.$store.state.scheduleappointment.form.provider_id="";
			this.$store.state.scheduleappointment.form.coordinator_staff_id="";
			this.$store.state.scheduleappointment.form.is_provider_occupied=true;
			this.$store.state.scheduleappointment.form.crm_prospect_patient_status="";
			this.$store.state.scheduleappointment.form.room_id="";
			this.$store.state.scheduleappointment.form.supervisor_id="";
			this.$store.state.scheduleappointment.form.appointment_profile_id="";
			Object.assign(this.$store.state.scheduleappointment.form);
		} else {
			await this.$store.dispatch("postEditData", {
				dataType: "scheduleappointment", // pass the module name
				formType: "form", // pass the propery name
				id: this.$route.params.appointments_id,
			});
			if(this.form.consent_form_ids != null){
				this.selected_forms = this.form.consent_form_ids.split(",");
				axios.post("appointment/signed-consent-form",{appointment_id:this.form.id,form_id:this.selected_forms})
					.then((response) =>{
						if (response.data.data.length>0) {
							for (const key in response.data.data) {
								if (Object.hasOwnProperty.call(response.data.data, key)) {
									const element = response.data.data[key];
									if(!this.signedConsentForm.includes(element.form_id)){
										this.signedConsentForm.push(element.form_id)
										this.signedConsentFormmsg+=element.consent_form_name+" is signed  by the patient.";
									}
								}
							}
						}
					}
				);
			}
			this.form.is_provider_occupied = this.form.is_provider_occupied == 1 ? true : false;

			this.form.allow_overlap = this.form.allow_overlap == 1 ? true : false;

			this.allowCheckBox = this.form.allow_overlap;

			this.default_color = this.form.color_code;
			this.schedule_time = moment(this.form.schedule_time).format('MM-DD-YYYY hh:mm A');
			axios.post("/json_dropdown/" + this.form.office_id +"/room")
			.then((response) => (
				this.rooms = response.data.data
			));
			this.duration_value = this.form.duration;
			this.schedule_end_time = moment(this.form.schedule_time).add(this.form.duration, 'minutes').format('MM-DD-YYYY hh:mm A');
			this.$store.state.scheduleappointment.appointment_id = this.form.id;
			this.$store.state.scheduleappointment.schedule_date = moment(this.form.schedule_time).format('MM/DD/YYYY');
			this.OfficeChange("editapi");
		}  

		               
	},
	created(){
		setTimeout(() => {
			if(this.reminderProfileChanged == false){
				$("#salutationselect").val('')
			}
		}, 500);
	},
	computed: {
		form() {
			return this.$store.state.scheduleappointment.form;
		},
		vuelidateExternalResults() {
			return this.$store.state.scheduleappointment.vuelidateExternalResults;
		},
		validationRules() {
			return this.$store.state.scheduleappointment.validationRules;
		},
		ActiveProvider() {
			return this.$store.state.auth;
		},
		title() {
		  return this.$route.name === "EditAppointment"
			? "Edit Schedule Appointment"
			: "Schedule Appointment";
		},
		button() {
			return this.$route.name === "EditAppointment"
				? "Update"
				: "Save";
		},
		...mapGetters("auth", ["user"]),
	},
	methods: {
		reset() {
			this.searchKey = this.searchKey + 1;
		},
		accessQuestionnarie(){
			var appointment_id=$(".portal_question").attr('data-appointment_id');
			var patient_id=$(".portal_question").attr('data-patient_id');
			var clinic_id=$(".portal_question").attr('data-clinic_id');
			axios.post("patient/pp-questionnarie",{appointment_id:appointment_id,patient_id:patient_id,clinicId:clinic_id})
				.then((response) =>{
				this.moshaToast("Questionnarie sent successfully.", "success"); 
			});		
		},
		accesspPatientportal(){
			var id =  $(".portal_active").attr('data-id');
			var email = $(".portal_active").attr("data-email");
			var dob  = $(".portal_active").attr("data-dob");
			var pp_id = $(".portal_active").attr("data-ppid");
			var cell_phone = $(".portal_active").attr("data-cellphone");
			var unique_id = $(".portal_active").attr("data-uniqueid");
			var pp_status=$(".portal_active").attr("data-pp_status");
			if(pp_id != 0){
				if (pp_status==1) {
					this.moshaToast("Please Enable patient portal","danger"); 
				}else{
					this.moshaToast("PurePORTAL already Activated","danger"); 
				}
			}else{
				if(cell_phone != null && email != null && dob != null){
					var dob = moment(dob).format('YYYY-MM-DD');
					axios.post("patient/pp-active",{unique_id:unique_id,email:email,dob:dob,isValid:0,patient_id:id,cell_phone:cell_phone})
					.then((response) =>{
						if (response.data.status == 'success') {
							this.moshaToast("The patient will receive an email to add the PurePORTAL password.","success"); 
						} else {
							this.moshaToast(response.data.message,"info"); 
						}
					});
				}
				else{
					this.moshaToast("Either Cell Phone, Email Id or DOB details are missing","danger"); 
				}
			}
    	},
		timeopen(args) {
      		if (args.popup.element.className.indexOf("e-datetimepicker") > -1) {
        		var list = args.popup.element.querySelectorAll(".e-list-item");
        		for (var item = 0; item < list.length; item++) {        			
        			if($(list[item]).attr("data-value") == '8:00 AM'){
        				var start_time_array = $(list[item]).attr("id").split("-");
        				var start_time  = start_time_array[1];
        			}
        			if($(list[item]).attr("data-value") == '8:00 PM'){
        				var end_time_array = $(list[item]).attr("id").split("-");
        				var end_time  = end_time_array[1] - 1;
        			}
        			if(start_time != undefined || end_time != undefined){        				
        				if (item < start_time || item > end_time) {
	            			list[item].className = list[item].className + " e-hidden";
	          			}
        			}
        			else{
        				list[item].className = list[item].className + " e-hidden";
        			}
          			
        		}
      		}
    	},
    	getPatinetPackage(patient_id) {
			
    		axios.get(`/patient-package/active-packages/${patient_id}`)
    			.then((res) => {
    				this.patient_package = res.data.data;
					
    			});
				
    	},
		getPreAppointmentInfoById(id) {
    		axios.get('pre-appointment/edit/'+id)
    		.then((response) => {
    			this.form.status = 1;
    			this.form.type = "Office";
    			this.form.action = "added";
    			this.form.manual_appointment = true;
    			this.form.pre_appointment = response.data.data;
    			this.form.office_id = this.form.pre_appointment.office_id;
    			this.form.reason_for_visit = this.form.pre_appointment.reason_for_visit;
    			this.OfficeChange();
    			this.schedule_time = moment(this.form.pre_appointment.schedule_time).format('MM-DD-YYYY hh:mm A');
    			this.form.schedule_time = moment(this.form.pre_appointment.schedule_time).format('YYYY-MM-DD hh:mm a');
    			this.form.schedule_end_time = moment(this.form.pre_appointment.schedule_time).add(this.form.pre_appointment.duration, 'minutes').format('YYYY-MM-DD hh:mm a');    			
    			this.duration_value = this.form.pre_appointment.duration;
    			this.form.duration = this.form.pre_appointment.duration;
    			this.form.is_provider_occupied = this.form.pre_appointment.is_provider_occupied;
    			this.schedule_end_time = moment(this.form.pre_appointment.schedule_time).add(this.form.pre_appointment.duration, 'minutes').format('MM-DD-YYYY hh:mm A');
    			this.$store.state.scheduleappointment.schedule_date = moment(this.form.pre_appointment.schedule_time).format('MM/DD/YYYY');
		     });
		},
		delete_reminder(index){
			this.form.reminderdata.splice(index, 1);
		},
		reminderview(index){
    		var id = this.form.reminderdata[index].reminder_content;
    		axios.get('email-template/edit/'+id)
    		.then((response) => {
		    	this.subjectline = response.data.data.subject_line
		    	this.body_content = response.data.data.body_content
		     });
    	},
		reminder_list(){
			var date = Date.parse(moment(this.form.schedule_time).format('YYYY-MM-DD HH:mm:ss'));
			if (date < Date.now()) {
				this.warning = "Time has already passed.";
			}else{
				this.warning = "";
			}
		    var id = $("#salutationselect").val();
		    this.form.reminder_profile_id = id;
		    this.change_reminderprofile(id);
		},
		changeCoordinator($event) {
			var options = $event.target.options;
			if (options.selectedIndex > -1) {
				this.form.is_provider_occupied = options[options.selectedIndex].getAttribute('data-need-provider-help-for-appt') == 1 ? true : false;
			} else {
				this.form.is_provider_occupied = true;
			}			
			this.checkslot();
		},
		changepatientPackage($event) {
			var options = $event.target.options;
			this.form.package_set=1;
			this.form.package_name=options[options.selectedIndex].getAttribute('data-package_name');
			this.form.price_per_treatment=options[options.selectedIndex].getAttribute('data-package_per_price');
			this.package_id=options[options.selectedIndex].getAttribute('value');
			$("#packageapp").removeClass("d-none");
			
		},
		statusChange($event){
            var options = $event.target.options;
          	var status=options[options.selectedIndex].getAttribute('value');
			if(status==8){
				this.form.package_set=1;
			}
            
        },
		change_reminderprofile(id){
			if (id) {
				axios.post('/reminder/edit',{id:id})
			    .then((response) => {					
			    	this.form.reminderdata  = response.data.data.reminderdata;
			     });	
			}
		},
		sloat_book(id){
			for (var i = 0; i < id; i++) {
				this.form.recurrencedate.push($("#free_sloat"+i).val());
			}
			this.recurringcheckSchedule();
		},
		changeroom(){
			if(this.form.is_recurring == true){
				this.getrecurring_end_date();
			}
		},
		getrecurring_end_date(){
			if(this.recurs_every<1) {
				this.recurs_every = 1;
			}
			if(this.frequency=='Week(s)') {
				this.repeat_every_max = 52;
				if(this.recurs_every>this.repeat_every_max) {
					this.recurs_every = this.repeat_every_max;
				}
				this.recurringEndDateWeekly();
			} else {
				this.repeat_every_max = 12;
				if(this.recurs_every>this.repeat_every_max) {
					this.recurs_every = this.repeat_every_max;
				}
				this.recurringEndDateMonthly();
			}
			this.recurringcheckSchedule();
		},
		recurringEndDateWeekly(){
			var time =  moment($("#schedule_time").val()).format('hh:mm a')
			this.recurrencedate = [];
			var weekday_array = Array();
			var weekday_array1 = Array();
			$("input:checkbox[name=Weekday]:checked").each(function() {
                weekday_array.push($(this).val());
                weekday_array1.push($(this).attr("data-id"));
	        });
			if(weekday_array.length==0) {
				weekday_array.push(moment($("#schedule_time").val()).format('dd').toUpperCase());
			}
			if(weekday_array1.length==0) {
				weekday_array1.push(moment($("#schedule_time").val()).format('dd'));
			}
			var recurring_start_date = $("#schedule_time").val();
			var recurring_start_date1 = moment(recurring_start_date).add(52, 'W').subtract(1, 'days');
			if(this.endType=='count') {
				let counter = parseInt($('#count').val()) - 1;
				if(!counter || counter<1) {
					counter = 0;
				}
				var recurring_start_date1 = moment(recurring_start_date).add((parseInt(counter)+50), 'W');
			} else if(this.endType=='until') {
				if(this.recurring_end_date == "") {
					var recurring_start_date1 = moment(recurring_start_date).add(2, 'M').subtract(1, 'days'); 
					this.recurring_end_date = moment(recurring_start_date1).format('MM-DD-YYYY');
				} else {
					this.recurring_end_date = $('#recurring_end_date').val();
					var recurring_start_date1 = this.recurring_end_date;
				}
			} else {
				// never = Yearly
				var recurring_start_date1 = moment(recurring_start_date).add(52, 'W').subtract(1, 'days');
			}
	        var recurring_end_date = moment(recurring_start_date1).format('MM-DD-YYYY');
	        if(this.frequency == "Week(s)") {
	        	var rules = "FREQ=WEEKLY;BYDAY="+weekday_array.toString()+";INTERVAL="+this.recurs_every+";UNTIL="+moment(recurring_end_date).format('YYYYMMDD')+";"
	        	this.form.recur_description	 = "Repeats weekly on "+weekday_array1.toString()+" starting "+recurring_start_date+" until "+recurring_end_date
	        } else {
				if(this.repeat_on == "day"){
	        		var rules = "FREQ=MONTHLY;BYDAY="+this.byday+";BYSETPOS="+this.bystep+";INTERVAL="+this.recurs_every+";UNTIL="+moment(recurring_end_date).format('YYYYMMDD')+";";
	        	}
	        	else{
	        		var bymonth = $("#bymonth").val();
	        		var rules = "FREQ=MONTHLY;BYMONTHDAY="+bymonth+";INTERVAL="+this.recurs_every+";UNTIL="+moment(recurring_end_date).format('YYYYMMDD')+";";
	        	}
	        	this.form.recur_description	 = "Repeats monthly on "+weekday_array1.toString()+" starting "+recurring_start_date+" until "+recurring_end_date
	        }
			this.form.RecurrenceRule=rules;
			let dates = []
			if(weekday_array.length>0) {
				let recObject = this.$refs.EditorObj;
				dates = recObject.getRecurrenceDates(new Date(moment(recurring_start_date).format('MM-DD-YYYY')),rules);
			}
			let loopCounter = dates.length;
			if(this.endType=='count') {
				loopCounter=parseInt($('#count').val())
			}
			for (let index = 0; index < loopCounter; index++) {
	        	var dayname = moment(String(new Date(dates[index]).toString())).format('ddd');
	        	var dater = moment(String(new Date(dates[index]).toString())).format('YYYY-MM-DD');
	        	if(dayname == "Sun"){
					var date = new Date(dater); 
					date. setDate(date. getDate() + 1); 
					this.recurrencedate.push(moment(date).format('YYYY-MM-DD')+" "+time);	            
	        	}
	        	else if(dayname == "Sat"){
					var date = new Date(dater); 
					date. setDate(date. getDate() + 2); 
					this.recurrencedate.push(moment(date).format('YYYY-MM-DD')+" "+time);	            
	        	}
	        	else{
	            	this.recurrencedate.push(moment(String(new Date(dates[index]).toString())).format('YYYY-MM-DD')+" "+time);	            
	            }
	        }
	        this.form.recurrencedate = this.recurrencedate;
		},
		recurringEndDateMonthly() {
			this.recurrencedate = [];
			var weekday_array = Array();
			var weekday_array1 = Array();
			var recurring_start_date = $("#schedule_time").val();
			var time =  moment(recurring_start_date).format('hh:mm a')
			var recurring_start_date1 = moment(recurring_start_date).add(12, 'M').subtract(1, 'days');
			weekday_array.push(moment(recurring_start_date).format('dd'));
			weekday_array1.push(moment(recurring_start_date).format('dddd'));
			this.monthDay = moment(recurring_start_date).format('DD');
			if(this.endType=='until') {
				if(this.recurring_end_date == "") {
					var recurring_start_date1 = moment(recurring_start_date).add(2, 'M').subtract(1, 'days'); 
					this.recurring_end_date = moment(recurring_start_date1).format('MM-DD-YYYY');
				} else {
					this.recurring_end_date = $('#recurring_end_date').val();
					var recurring_start_date1 = this.recurring_end_date;
				}
			}
			if(this.repeat_on == "day"){
				var rules = "FREQ=MONTHLY;BYDAY="+this.byday+";BYSETPOS="+this.bystep+";INTERVAL="+this.recurs_every+";UNTIL="+moment(recurring_start_date1).format('YYYYMMDD')+";";
			} else {
				// var bymonth = $("#bymonth").val();
				var rules = "FREQ=MONTHLY;BYMONTHDAY="+this.monthDay+";INTERVAL="+this.recurs_every+";UNTIL="+moment(recurring_start_date1).format('YYYYMMDD')+";";
			}
			this.form.RecurrenceRule=rules;
			this.form.recur_description	 = "Repeats monthly on "+weekday_array1.toString()+" starting "+recurring_start_date+" until "+recurring_end_date
			
			let dates = [];
			let recObject = this.$refs.EditorObj;
			dates = recObject.getRecurrenceDates(new Date(moment(recurring_start_date).format('MM-DD-YYYY')),rules);
			let loopCounter = dates.length;
			if(this.endType=='count') {
				loopCounter=parseInt($('#count').val())
			}
			for (let index = 0; index < loopCounter; index++) {
	        	var dayname = moment(String(new Date(dates[index]).toString())).format('ddd');
	        	var dater = moment(String(new Date(dates[index]).toString())).format('YYYY-MM-DD');
	        	if(dayname == "Sun"){
					var date = new Date(dater); 
					date. setDate(date. getDate() + 1); 
					this.recurrencedate.push(moment(date).format('YYYY-MM-DD')+" "+time);	            
	        	}
	        	else if(dayname == "Sat"){
					var date = new Date(dater); 
					date. setDate(date. getDate() + 2); 
					this.recurrencedate.push(moment(date).format('YYYY-MM-DD')+" "+time);	            
	        	}
	        	else{
	            	this.recurrencedate.push(moment(String(new Date(dates[index]).toString())).format('YYYY-MM-DD')+" "+time);	            
	            }
	        }

			this.form.recurrencedate = this.recurrencedate;
		},
		recurringcheckSchedule(){
			if(this.checkOverlapping(this.form.provider_id) == false && this.allowCheckBox == false){
				axios.post("/appointment/recurring-check-schedule",{
					is_provider_occupied :this.form.is_provider_occupied,
					app_coordinator_staff_id :this.form.coordinator_staff_id,
					office_id:this.form.office_id,
					app_provider_id :this.form.provider_id,
					recurrencedate:this.form.recurrencedate,
					duration:this.form.duration,
					room_id:this.form.room_id,
					Id:this.form.id,
				})
				.then((response) => {
					this.form.recurrencedate = [];
					if(response.data.data.error != null){
						this.recurringerrormessage = "Time Slot Not Available";			
						this.recurrenceerrordate =  response.data.data.error
						this.form.recurrencedate =  response.data.data.success
					}
					else{
						this.recurrenceerrordate =[];
						this.form.recurrencedate =  response.data.data.success
						this.recurringerrormessage = null;			
					}
				});
			}
		},
		getnever_end(){
			var date = new Date($("#recurring_start_date").val()); 
			date. setDate(date. getDate() + 364); 
			this.recurring_end_date = moment(date).format('MM-DD-YYYY');
		},
		recurring(){
			if(this.form.is_recurring == true){
				this.getrecurring_end_date();
				$('.weekday_checkbox').prop('checked', false);
				$('.btn_weekdays').addClass('btn-secondary').removeClass('btn-info');
				var schedule_date = $("#schedule_time").val();
				$('.'+moment(schedule_date).format('dd')).prop('checked', true);
				$('.btn_'+moment(schedule_date).format('dd')).addClass('btn-info').removeClass('btn-secondary');
				this.recurring_start_date = moment(schedule_date).format('MM-DD-YYYY');
				this.recurring_start_time = moment(schedule_date).format('hh:mm A');				
			}
		},
		weekday_btn_click(btn){
			if($('.'+btn).prop('checked')) {
				$('.'+btn).prop('checked', false);
				$('.btn_'+btn).addClass('btn-secondary').removeClass('btn-info');
			} else {
				$('.'+btn).prop('checked', true);
				$('.btn_'+btn).addClass('btn-info').removeClass('btn-secondary');
			}
			this.getrecurring_end_date();
		},
		getrecurring_start_date(){
			if($('#recurring_start_date').val() == '') {
	        	this.minVal = null;
	      	} else {
	        	this.minVal =  $('#recurring_start_date').val();
	      	}
		},
		checkAll(){
			$('.weekday_checkbox').prop('checked', true);
		},
		uncheckAll(){
			$('.weekday_checkbox').prop('checked', false);	
		},
		getlist(obj) {
		  if (obj.length > 1) {
			this.$refs.autocomplete.$el.classList.add("selectloader");
			axios
			  .post("/patient/patient-search", {
				 search_text: obj,
			  })
			  .then((response) => {
				this.result = response.data.data.map((patient) => {
				  return { id: patient.id, name: patient.first_name+" "+patient.last_name }
				});
				this.$refs.autocomplete.$el.classList.remove("selectloader");
			  });
		  }
		  if (obj.length == 0) {
			this.result = {};
		  }
		},
		resultsvalue(obj) {
		  this.$emit("clicked", obj);
		  this.$refs.autocomplete.searchText = obj.name;
		  this.$store.state.scheduleappointment.form.patient_id = obj.id
		  this.result = {};
		  this.focusout();
		},
		focusout() {
		  const btn = this.$refs.autocomplete;
		  btn.$el
			.querySelector(".vue3-autocomplete-container > .autosearch-list")
			.blur();
		},
		officeScheduleCheck(){
			if(this.form.schedule_time && this.form.schedule_time!='' && this.form.office_id!=''){
				axios.post(`office/daywise-schedule`,{'office_id': this.form.office_id, 'schedule_date': this.form.schedule_time })
					.then((res) => {
						if(res.data.message != "schedule available"){
							$("#officeScheduleError").html(res.data.message);
						}else{
							$("#officeScheduleError").html("");
						}
					});				
			}else{
				document.getElementById('officeScheduleError').innerHTML = "";
			}
		},
		displaySmallLoader(parentElemId) {
			let width = "20px";
			if(document.getElementById(parentElemId).parentElement.firstElementChild.className=="small-loader mt-5") {
				document.getElementById(parentElemId).parentElement.removeChild(document.getElementById(parentElemId).parentElement.firstElementChild);
			}
			if(document.getElementById(parentElemId).parentElement.firstElementChild) {
				if(document.getElementById(parentElemId).parentElement.firstElementChild.clientWidth>0) {
					width = document.getElementById(parentElemId).parentElement.firstElementChild.clientWidth + "px";
				}
				let position = document.getElementById(parentElemId).parentElement.firstElementChild.getBoundingClientRect();
				let positionLocket = document.getElementById("locked").parentElement.firstElementChild.getBoundingClientRect();
				const node = document.createElement("div");
				node.className = "small-loader mt-5";
				const DOM_img = document.createElement("img");
				DOM_img.src = "images/loader.gif";
				node.appendChild(DOM_img);
				document.getElementById(parentElemId).parentElement.insertBefore(node, document.getElementById(parentElemId).parentElement.firstChild);

				document.getElementById(parentElemId).parentElement.firstElementChild.style.position = "absolute";
				document.getElementById(parentElemId).parentElement.firstElementChild.style.textAlign = "right";
				document.getElementById(parentElemId).parentElement.firstElementChild.style.top = (position.top-positionLocket.top)+"px";
				document.getElementById(parentElemId).parentElement.firstElementChild.style.left = (position.left-positionLocket.left)+"px";
				document.getElementById(parentElemId).parentElement.firstElementChild.style.width=width;
				document.getElementById(parentElemId).parentElement.firstElementChild.style.opacity="1";
			}
			
		},
		hideSmallLoader(parentElemId) {
			if(document.getElementById(parentElemId).parentElement.firstElementChild.className=="small-loader mt-5") {
				document.getElementById(parentElemId).parentElement.removeChild(document.getElementById(parentElemId).parentElement.firstElementChild);
			}
		},
		OfficeChange(flag){
			if(flag != 'editapi'){
				this.form.provider_id = null;
				this.form.supervisor_id = null;
				this.form.room_id = null;
			}

			/*office schedule check for day*/
			this.officeScheduleCheck();
			if(this.form.office_id){
				axios.post("/json_dropdown/" + this.form.office_id +"/room")
				.then((response) => (
					this.rooms = response.data.data
				));
			}
				

			this.displaySmallLoader('providerScheduleError');
			axios.post("json_dropdown/office-provider",{Providers: this.accessproviders.map(({ id }) => id) , office_id: this.form.office_id})
			.then((response) => {
				if(response.data.data.loop_data!=undefined){
					this.providers = response.data.data.loop_data.map((item) => {
						if(this.user.user_type == 'provider'){
							if(item.provider_type == 1 || item.id == this.patientDetail.primary_provider_id){
								return item;
							}
						}else{
							return item;
						}
					}).filter(n => n);					
				}
				if(this.providers.map(({ id }) => id).includes( response.data.data.office_primary_provider ) && flag != 'editapi'){
						this.form.provider_id = response.data.data.office_primary_provider;
				}else{
					if (this.$route.name === "PatientAppointment") {
						var activeprovider = localStorage.getItem('activeProvider');
						if(activeprovider == 'all'){
							if(this.user.user_type == 'provider'){
								if(this.providers.map(({ id }) => id).includes(this.user.user_id)){
									this.form.provider_id = this.user.user_id;
								}
							}else{
								if(this.providers.map(({ id }) => id).includes(this.user.primary_provider_id)){
									this.form.provider_id = this.user.primary_provider_id;
								}
							}
						}else{
							if(this.providers.map(({ id }) => id).includes(localStorage.getItem('activeProvider'))){
								this.form.provider_id = localStorage.getItem('activeProvider');
							}
						}
					}
				}
				if (this.form.pre_appointment) {
					this.form.provider_id = this.form.pre_appointment.provider_id;
				}
				this.checkOverlapping(this.form.provider_id);
				this.hideSmallLoader('providerScheduleError');
			});
			axios.post("json_dropdown/office-provider",{Providers: this.accessproviders.map(({ id }) => id) , office_id: this.form.office_id, is_supervisor: 1})
			.then((response) => {
				this.superproviders = response.data.data.loop_data;  
				if(flag != 'editapi'){
					this.form.supervisor_id = response.data.data.office_supervisor_provider; 
				}  
			});
			axios.post("json_dropdown/office-staff",{ office_id: this.form.office_id})
			.then((response) => {
				this.officeStaffs = response.data.data.loop_data;
				if (this.form.pre_appointment) {
					this.form.coordinator_staff_id = this.form.pre_appointment.coordinator_staff_id;
					this.checkslot();
				}
			});
			
		},
		providerScheduleCheck(){
			if(this.form.provider_id!='' && this.form.office_id!='' && this.form.schedule_time && this.form.schedule_time!=''){
				axios.post(`provider/daywise-and-officewise-schedule`,{'provider_id' : this.form.provider_id,'office_id': this.form.office_id, 'schedule_date': this.form.schedule_time })
						.then((res) => {			
							if(res.data.message!='schedule available'){
								$("#providerScheduleError").html(res.data.message);
							}else{
								$("#providerScheduleError").html("");
							}			
						});
			}else{
				$("#providerScheduleError").html("");
			}
			this.checkOverlapping(this.form.provider_id);
		},
		superviserCheck(e){
			this.providerScheduleCheck();
			if (e.target.options.selectedIndex > -1 && this.form.provider_id != null && this.form.provider_id != '') {
				const theTarget = e.target.options[e.target.options.selectedIndex].dataset;
				if(theTarget.is_supervisor != 1){
					this.$store.state.is_supervisor_required = true
				}else{
					this.$store.state.is_supervisor_required = false
				}
			}            
		},
		onChangeColor: function(args) {
			this.form.color_code = args.currentValue.hex;
		},
		consentformsChange(args){
			this.form.consent_form_ids = args.value
		},
		onChangeAppointmentProfile(e) {
			if (e.target.options.selectedIndex > -1) {
				const theTarget = e.target.options[e.target.options.selectedIndex].dataset;
				this.form.color_code = theTarget.color_code
				this.default_color = theTarget.color_code
				this.form.duration = theTarget.default_duration
				this.duration_value = theTarget.default_duration
				if (!this.form.reason_for_visit) {
					this.form.reason_for_visit = theTarget.reason_for_visit
				}
				if(theTarget.consent_form_ids != null){
					this.selected_forms = theTarget.consent_form_ids.split(",");
				}else{
					this.selected_forms = '';
				}
			}
		},
		durationChange(e){
			if(e.event){
				if(this.form.schedule_time != null){
					this.schedule_end_time = moment(this.form.schedule_time).add(e.value, 'minutes').format('YYYY-MM-DD hh:mm A');
					this.form.schedule_end_time = moment(this.form.schedule_time).add(e.value, 'minutes').format('YYYY-MM-DD hh:mm a');
				}
				this.duration_value = e.value;
				this.form.duration = e.value;
			}
		},
		
		scheduleDate(e){
			if(e.event){
				
				if(this.form.is_recurring == true){
					$('.weekday_checkbox').prop('checked', false);
					$('.btn_weekdays').addClass('btn-secondary').removeClass('btn-info');
					$('.'+moment(e.value).format('dd')).prop('checked', true);
					$('.btn_'+moment(e.value).format('dd')).addClass('btn-info').removeClass('btn-secondary');
					this.recurring_start_date = moment(e.value).format('MM-DD-YYYY');
					this.recurring_start_time = moment(e.value).format('hh:mm A');
					this.getrecurring_end_date();

				}
				if (moment(e.value).format('HH:mm:SS') == '00:00:00') {
					e.value = moment(moment(e.value).format('YYYY-MM-DD')+'T'+moment().format('HH:mm'));
					this.schedule_time = moment(e.value).format('YYYY-MM-DD hh:mm A');
				}
				this.form.schedule_time = moment(e.value).format('YYYY-MM-DD hh:mm a');
				this.form.schedule_end_time = moment(e.value).add(this.duration_value, 'minutes').format('YYYY-MM-DD hh:mm a');
				this.schedule_end_time = moment(e.value).add(this.duration_value, 'minutes').format('YYYY-MM-DD hh:mm A');
				this.checkslot();
				this.reminder_list();				
				var selectedDate = new Date(moment(this.form.schedule_time).format('MM-DD-YYYY HH:MM:SS'));
   				var now = new Date();
				if (selectedDate < now) {
					this.recurringform = true;					
					this.recurring_error = "(Recurring Appointments cannot be scheduled before the current time.)";
					this.form.is_recurring = false;
				}
				else{
					this.recurringform = false;
					this.recurring_error = '';
				}

				this.officeScheduleCheck();
				this.providerScheduleCheck();
			}
		},
		rescheduleeDate(e){
			if(e.event){
				this.form.reschedule_time = moment(e.value).format('YYYY-MM-DD hh:mm a');
			}
			axios.post("/appointment/check-schedule",{
				app_coordinator_staff_id :this.form.coordinator_staff_id,
				is_provider_occupied :this.form.is_provider_occupied,
				app_provider_id :this.form.provider_id,
				StartTime:this.form.reschedule_time,
				duration:this.form.duration,
				Id:this.form.id,
			})
			.then((response) => {
				this.checkuserschedule();
			}).catch((error) => {
				this.userschedule = true
				this.errormessage= error.response.data.data.schedule_time;
				this.schedule_type = "reschedulee";
			});
		},
		scheduleEndDate(e){
			if(e.event){
				this.form.schedule_end_time = moment(e.value).format('YYYY-MM-DD hh:mm a');            
				var a = moment(this.form.schedule_end_time);
				var b = moment(this.form.schedule_time);
				this.duration_value = a.diff(b, 'minutes');
				this.form.duration = a.diff(b, 'minutes');
				this.checkslot();
			}
		},
		submitForm() {
			
			this.v$.$validate();			
      		if (!this.v$.$error) {
				  if(this.errormessage == null){
					if (this.package_id>0) {
						this.form.package_id=this.package_id;
					}
					if(this.form.package_set==undefined){
						this.form.package_set=0;
					}
					
					  if(this.form.is_recurring == true){
				        this.form.is_recurring = 1;
						this.form.unique_id = this.$store.state.patientDetail.patient_unique_id;
				        var schedule_date = $("#schedule_time").val();
				        if($("."+moment(schedule_date).format('dd')).is(":checked")) {
						    if(this.recurrencedate.length > 0){
						    	if(this.recurringerrormessage == null){
									this.form.recurrencedate.unshift(this.form.schedule_time);
							        var first_date = moment(this.form.recurrencedate[0]).format('MM-DD-YYYY');
							        var schedule_time = moment(this.form.schedule_time).format('MM-DD-YYYY');
							        if(first_date == schedule_time){
								        var url = `appointment/recurring-appointments`;
								        this.postForm(url, "scheduleappointment", true, "json");
								    }
								    else{ 
										this.moshaToast("The schedule date and day doesn't match.", "danger");   		    	
								    }
							    }
						    }else{
						    	this.moshaToast("Last Appointment is mandatory.", "danger");   	
						    }
						}
						else{
						    	this.moshaToast("The schedule date and day doesn't match.", "danger");   	
						}
					}
					else{
						this.form.allow_overlap = this.allowCheckBox;
						var url = `appointment/store`;
						if (this.$route.name === "PatientAppointment") {
							this.form.action = "added";
							this.form.manual_appointment = true;
						}
						else{
							this.form.action = "changed";
							this.form.manual_appointment = true;
						}
						if(this.provider_not_allow == false){
							this.postForm(url, "scheduleappointment", true, "json");
						}
					}
				}
			}
		},
		formPostSuccess(data) {
			this.$store.state.scheduleappointment.appointment_id = data.id;
			this.$store.state.scheduleappointment.schedule_date = moment(data.schedule_date).format('MM/DD/YYYY');
			this.form.package_set=0;
			$("#packageapp").addClass("d-none");
			if (this.$route.name === "PatientAppointment") {
				this.moshaToast("Record added successfully.", "success");                
				if(this.patienttextbox == 1 || this.form.is_recurring == 1 || this.form.is_recurring == true ){
					this.$router.push('/appointments/calendar'); 
				}
				else{
					if (this.form.pre_appointment) {
						this.form.pre_appointment = '';
						localStorage.setItem("refresh_table", Date.now());
						window.close();
					}
					this.$router.push(`/patient-chart/${this.$route.params.id}/edit-appointment/`+data.appointment_number+'');     
				}
			}
			else{
				this.moshaToast("Record updated successfully.", "success");   
			}          
		},
		close(){
			if(this.$route.params.id == 'new'){
				this.$router.push(`/availability-search`); 
			}else{
				this.$router.push(`/patient-chart/${this.$route.params.id}/appointments/`); 
			}
		},
		checkslot(){
			if(this.checkOverlapping(this.form.provider_id) == false && this.allowCheckBox == false){
				axios.post("/appointment/check-schedule",{
					is_provider_occupied :this.form.is_provider_occupied,
					app_coordinator_staff_id :this.form.coordinator_staff_id,
					app_provider_id :this.form.provider_id,
					StartTime:this.form.schedule_time,
					duration:this.form.duration,
					Id:this.form.id,
				})
				.then((response) => {
					this.checkuserschedule();
				}).catch((error) => {
					this.errormessage= error.response.data.data.schedule_time;
					this.userschedule = true
				});
			}
		},
		suggestion_schedule(){
			this.choose_slot_loader = true;
			axios.post("/appointment/suggestion-schedule",{
				is_provider_occupied :this.form.is_provider_occupied,
				app_coordinator_staff_id :this.form.coordinator_staff_id,
				office_id:this.form.office_id,
				app_provider_id :this.form.provider_id,
				StartTime:this.form.schedule_time,
				duration:this.form.duration,
			})
			.then((response) => {                   
				this.suggestion_list = response.data.data;
				this.choose_slot_loader = false;
			});

			$(".free_slot").hide();
			$(".check_box").click(function() {
				if($(".free_slot").hasClass("d-block")){
					$(this).text("more");
					$(".free_slot").removeClass("d-block");
				}else{
					$(this).text("less");
					$(".free_slot").addClass("d-block");
				}
			});
		},
		Schedule(){
			if(this.schedule_type == "reschedulee"){
				var date = moment(this.form.schedule_time).format('MM-DD-YYYY');
				var dates = moment(this.form.schedule_time).format('YYYY-MM-DD');
				this.form.reschedule_time = dates+" "+this.suggestion_time;
				this.rescheduled_time = date+" "+this.suggestion_time;				
			}
			else{
				var date = moment(this.form.schedule_time).format('MM-DD-YYYY');
				var dates = moment(this.form.schedule_time).format('YYYY-MM-DD');
				this.schedule_time = date+" "+this.suggestion_time;
				this.form.schedule_time = dates+" "+this.suggestion_time;           
				this.form.schedule_end_time = moment(this.form.schedule_time).add(this.form.duration, 'minutes').format('YYYY-MM-DD hh:mm a');
				this.schedule_end_time = moment(this.form.schedule_time).add(this.duration_value, 'minutes').format('YYYY-MM-DD hh:mm A');
			}
			this.userschedule = false
			this.errormessage = null;
			this.checkuserschedule();
		},
		checkuserschedule(){
			if(this.form.type != 'Tele Health' && this.form.type != 'Phone' && this.form.provider_id != null && this.form.schedule_time != null && this.form.office_id != null){
				axios.post("/check-user-schedule",{
					is_provider_occupied :this.form.is_provider_occupied,
					coordinator_staff_id :this.form.coordinator_staff_id,
					provider_id :this.form.provider_id,
					selectedTime:this.form.schedule_time,
					office_id:this.form.office_id,
				})
				.then((response) => {                   
					if(response.data.data.schedule_time == false){
						this.errormessage = 'The '+response.data.data.user_type+' is available to take only televisit. Please book the appointment as "Tele Health".';
					}else{
						this.errormessage = null;
					}
				});
			}else{
				this.errormessage = null;
			}
		},
		checkOverlapping(value){
			var allow_overlap = this.providers && this.providers.find(e => e.id == value);
			if(allow_overlap != undefined){
				this.allowOverLap = (allow_overlap.is_appointment_overlapping == 1) ? true : false;
				return (allow_overlap.is_appointment_overlapping == 1) ? true: false;
			}else{
				this.allowOverLap = false;
				return false;
			}
		},
		ChangeAllowOverLapping(){
			if(this.allowCheckBox){
				this.recurringerrormessage = null;
				this.errormessage = null;
			}else{
				this.checkslot();
			}
		},
		reminderTypeChange(index) {
			this.reminderdata[index].reminder_content = 0;
		},
		getReminderProfileSelected(item) {
			// this.form.reminder_profile_id
			this.form.reminder_profile_id = 0;
			if(this.form.reminderdata) {
				if(this.form.reminderdata.length>0) {
					for(var ii=0; ii<this.form.reminderdata.length; ii++) {
						if(this.form.reminderdata[ii]) {
							if(this.form.reminderdata[ii].reminder_profile_id) {
								if(this.form.reminderdata[ii].reminder_profile_id == item.id) {
									this.form.reminder_profile_id = item.id;
									this.reminderProfileChanged = true;
									return true;
									break;
								}
							}
						}
					}
					return false;
				} else {
					return false;
				}
			} else if(item.is_selected=="yes") {
				this.form.reminder_profile_id = item.id;
				return true;
			} else {
				return false;
			}
		}
	},
	validations() {
		return this.$store.state.scheduleappointment.validationRules;
	},
	watch: {		
		"$store.state.scheduleappointment.form.type": function() {
			this.checkuserschedule();
		},
	}      
}
	

</script>
